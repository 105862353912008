import goTo from 'vuetify/lib/services/goto'
import * as wanakana from 'wanakana'
import { audio } from '@/plugins/audio'

const state = {
  lessons: [],
  curLesson: [],
  lessonsIndex: 0
}

const getters = {
  lessons (state, getters, rootState, rootGetters) {
    const dailyLessonsRemaining = rootGetters['user/dailyLessonsRemaining']
    const batchSize =
      dailyLessonsRemaining > rootState.settings.lessonsBatchSize
        ? rootState.settings.lessonsBatchSize
        : dailyLessonsRemaining
    return state.lessons.slice(0, batchSize)
  },
  hideFurigana (state) {
    return state.curLesson.vocab === state.curLesson.vocab_kana || wanakana.isKatakana(state.curLesson.vocab)
  },
  lessonIndex (state) {
    return state.lessonsIndex
  },
  peakLesson (state, getters) {
    return state.lessonsIndex !== getters.lessons.length - 1
  }
}

const mutations = {
  initSession (state, { lessons }) {
    state.lessons = lessons
    state.lessonsIndex = 0
  },
  load (state) {
    state.curLesson = state.lessons[state.lessonsIndex]

    // Scroll to top instantly
    goTo(0, { duration: 0 })

    // Autoplay audio if enabled in settings
    if (this.state.settings.lessonsAudioAutoplay) {
      audio.playVocabAudio(state.curLesson.vocab_audio, state.curLesson.sentence_audio)
    }
  },
  next (state) {
    state.lessonsIndex++
  },
  previous (state) {
    state.lessonsIndex--
  },
  archive (state) {
    state.curLesson.archived = 1
    state.lessons.splice(state.lessonsIndex, 1)
  }
}

const actions = {
  initSession (context, lessons) {
    context.commit('initSession', { lessons })
    context.commit('load')
  },
  load (context) {
    context.commit('load')
  },
  next (context) {
    context.commit('next')
    context.commit('load')
  },
  previous (context) {
    context.commit('previous')
    context.commit('load')
  },
  archive (context) {
    const vocabId = context.state.curLesson.id
    context.dispatch('vocabulary/archive', [vocabId], { root: true })
    context.commit('archive')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
