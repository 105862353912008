import { render, staticRenderFns } from "./ToriiGateIcon.vue?vue&type=template&id=5da46596&scoped=true"
var script = {}
import style0 from "./ToriiGateIcon.vue?vue&type=style&index=0&id=5da46596&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5da46596",
  null
  
)

export default component.exports