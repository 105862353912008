<template>
<v-container>
<v-card class="mx-auto">
  <v-card-title class="justify-center">Work in progress</v-card-title>
  <v-card-text class="text-center">Oops, this page is not yet available in beta 🤷</v-card-text>
</v-card>
</v-container>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
  }
}
</script>
