<template>
<v-container fluid class="fill-height ma-0 pa-0">
  <v-row dense justify="center">
    <!--
    <v-col cols="3" align="center">
      <v-btn
        block
        :disabled="(japaneseInputExpected && awaitingResponse) || !curReview.vocab_kana"
        @click="playWordAudio">
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-volume-high</v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Vocab</div>
      </v-btn>
    </v-col>
    <v-col cols="3" align="center">
      <v-btn
        block
        :disabled="(japaneseInputExpected && awaitingResponse) || !curReview.sentence"
        @click="playSentenceAudio">
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-volume-high</v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Sentence</div>
      </v-btn>
    </v-col>
    <v-col cols="3" align="center">
      <v-btn v-if="awaitingResponse" block @click="nextReview">
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-debug-step-over</v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Skip</div>
      </v-btn>
    </v-col>
    -->
    <v-col cols="12" class="fill-height" style="position: relative;">
        <v-overlay absolute color="reviews" opacity="0.25" :value="awaitingResponse" class="rounded ma-4" @click.native="revealAnswer">
          <v-fade-transition>
            <v-btn v-show="showRevealButton" plain color="reviews">Flip</v-btn>
          </v-fade-transition>
        </v-overlay>
      <v-fade-transition hide-on-leave>
        <VocabInfoPanel v-if="!awaitingResponse" mode="reviews" toolbar-inline />
      </v-fade-transition>
    </v-col>
  </v-row>
  <v-app-bar app bottom dark>
    <v-spacer></v-spacer>
    <v-slide-y-reverse-transition>
      <v-btn v-show="showAnswerButtons" icon large color="success" class="mx-3" :disabled="!showAnswerButtons" @click="submit(true)">
        <v-icon>mdi-thumb-up</v-icon>
      </v-btn>
    </v-slide-y-reverse-transition>
    <v-fade-transition>
      <span v-show="!awaitingResponse">Did you know the answer?</span>
    </v-fade-transition>
    <v-slide-y-transition>
      <v-btn v-show="showAnswerButtons" icon large color="error" class="mx-3" :disabled="!showAnswerButtons" @click="submit(false)">
        <v-icon>mdi-thumb-down</v-icon>
      </v-btn>
    </v-slide-y-transition>
    <v-spacer></v-spacer>
  </v-app-bar>
</v-container>
</template>

<script>
import VocabInfoPanel from '@/components/VocabInfoPanel.vue'

export default {
  components: {
    VocabInfoPanel
  },
  data () {
    return {
      awaitingResponse: true,
      showRevealButton: true,
      showAnswerButtons: false
    }
  },
  computed: {
    curReview () {
      return this.$store.state.reviews.curReview
    },
    japaneseInputExpected () {
      return this.curReview.reviewMode.endsWith('ToJp')
    }
  },
  methods: {
    playWordAudio () {
      this.$emit('play-word-audio')
    },
    playSentenceAudio () {
      this.$emit('play-sentence-audio')
    },
    revealAnswer () {
      if (!this.showRevealButton) return
      this.$emit('response-submitted')
      this.awaitingResponse = false
      this.showRevealButton = false
      setTimeout(() => { this.showAnswerButtons = true }, 1000)

      // Autoplay audio after response depending on user settings
      if (this.$store.state.settings.reviewsVocabAudioAutoplay) {
        if (this.$store.state.settings.reviewsAudioPlayAtEnd && this.japaneseInputExpected) {
          this.$emit('play-audio')
        }
      }

      // Reveal furigana after response
      if (this.curReview.reviewMode === 'JpToEn' && this.$store.state.settings.reviewsKanaReveal) this.$emit('reveal-furigana')
    },
    submit (answeredCorrectly) {
      this.$emit('next-review', answeredCorrectly)
    },
    nextReview () {
      this.$store.dispatch('reviews/load')
      this.$emit('review-loaded')
      this.awaitingResponse = true
      this.showAnswerButtons = false
      setTimeout(() => { this.showRevealButton = true }, 1500)

      this.$emit('autoplay-audio')
    }
  }
}
</script>

<style scoped>
/* TODO: Without this, v-app-bar will be placed at the top even though the "bottom" prop is used.
This wasn't necessary in previous Vuetify 2 verions.
Could be a bug and might be resolved in Vuetify 3. */
.v-toolbar.v-toolbar--bottom {
  top: auto !important;
  bottom: 0 !important;
}
</style>
