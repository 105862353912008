import { render, staticRenderFns } from "./ReleaseNotes.vue?vue&type=template&id=38d0fd03&scoped=true"
import script from "./ReleaseNotes.vue?vue&type=script&lang=js"
export * from "./ReleaseNotes.vue?vue&type=script&lang=js"
import style0 from "./ReleaseNotes.vue?vue&type=style&index=0&id=38d0fd03&prod&lang=sass"
import style1 from "./ReleaseNotes.vue?vue&type=style&index=1&id=38d0fd03&prod&scoped=true&lang=sass"
import style2 from "./ReleaseNotes.vue?vue&type=style&index=2&id=38d0fd03&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d0fd03",
  null
  
)

export default component.exports