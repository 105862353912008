<template>
  <v-container fluid class="pa-0">
    <LoadingOverlay v-if="!loaded" />
    <template v-else>
      <v-toolbar dense dark>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon large to="dashboard">
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </template>
          Exit to Dashboard
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="$refs.cdArchive.show = true">
              <v-icon>mdi-archive</v-icon>
            </v-btn>
          </template>
          Archive item
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-icon v-on="on" class="mr-1">mdi-calendar</v-icon>
          </template>
          {{ dailyLessonsRemaining }} Lessons Available Today
        </v-tooltip>
        {{ dailyLessonsRemaining }}
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-icon v-on="on" class="ml-3 mr-1">mdi-check-bold</v-icon>
          </template>
          {{ dailyLessonsCompleted }} Lessons Completed Today
        </v-tooltip>
        {{ dailyLessonsCompleted }}
      </v-toolbar>
      <v-card tile flat color="lessons" align="center" justify="center" :height="$vuetify.breakpoint.smAndDown ? 150 : 250">
        <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col>
            <div v-if="!hideFurigana" :class="pitchAccentClass" class="white--text text-h4 text-md-h3 mb-2 mb-md-5" @click="toggleFuriganaVisibility" v-html="furiganaOrPlaceholder"></div>
            <div :class="pitchAccentClass" class="white--text text-h2 text-md-h1 font-weight-medium" v-html="vocab"></div>
          </v-col>
        </v-row>
        </v-container>
      </v-card>
      <VocabInfoPanel mode="lessons" />
      <v-app-bar app bottom dense>
        <v-spacer></v-spacer>
        <v-btn text x-large :disabled="lessonIndex === 0" @click="prevButton">
          <v-icon left>mdi-arrow-left</v-icon>
          Previous
        </v-btn>
        <v-btn text x-large @click="nextButton">
          {{ peakLesson }}
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-app-bar>
      <ConfirmDialog
        ref="cdArchive"
        :title="`Archive ${curLesson.vocab} ?`"
        message="The item will be removed from your lessons and review queue. It can be unarchived at any time (Dashboard => Browse)."
        :persistent="false"
        @confirm="archive"
      />
    </template>
  </v-container>
</template>

<script>
import vars from '@/vars.js'
import { utils } from '@/plugins/utils.js'
import { audio } from '@/plugins/audio.js'
import { pitchaccent } from '@/plugins/pitchaccent.js'
import VocabInfoPanel from '@/components/VocabInfoPanel.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'

export default {
  components: {
    VocabInfoPanel,
    ConfirmDialog,
    LoadingOverlay
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    curLesson () {
      return this.$store.state.lessons.curLesson
    },
    dailyLessonsRemaining () {
      return this.$store.getters['user/dailyLessonsRemaining']
    },
    dailyLessonsCompleted () {
      return this.$store.state.user.dailyStats[this.$store.state.user.dailyStats.length - 1].lessons
    },
    hideFurigana () {
      return this.$store.getters['lessons/hideFurigana']
    },
    lessonsCount () {
      return this.$store.getters['lessons/lessons'].length
    },
    lessonIndex () {
      return this.$store.getters['lessons/lessonIndex']
    },
    peakLesson () {
      return this.$store.getters['lessons/peakLesson'] ? 'Next' : 'Quiz'
    },
    vocab () {
      if (this.hideFurigana && this.$store.state.settings.lessonsPitchAccent && this.curLesson.accent) {
        return pitchaccent.generate(this.curLesson.vocab, this.curLesson.accent)
      } else {
        return this.curLesson.vocab
      }
    },
    furiganaOrPlaceholder () {
      if (this.$store.state.settings.lessonsHideKana) {
        return `<span>${this.$store.state.settings.placeholder}</span>`
      } else if (!this.$store.state.settings.lessonsPitchAccent || !this.curLesson.accent) {
        return `<span>${this.curLesson.vocab_kana}</span>`
      } else {
        return pitchaccent.generate(this.curLesson.vocab_kana, this.curLesson.accent)
      }
    },
    pitchAccentClass () {
      const theme = this.$vuetify.theme.dark ? 'dark' : 'light'
      const pClass =
        this.$store.state.settings.theme[theme].pitchAccentColors.enable &&
        !this.$store.state.settings.theme[theme].pitchAccentColors.disableInLessons
          ? 'pitch--colored'
          : 'pitch--plain'
      return pClass
    }
  },
  methods: {
    initSession () {
      // Filtering
      const arrFiltered = Array.from(this.$store.getters['vocabulary/lessons'])

      // Shuffling
      utils.shuffle(arrFiltered)

      // WaniKani mode: sort by prioritized DESC and then by WK level ASC
      if (this.$store.state.settings.studyMode.id === 6) {
        arrFiltered.sort((a, b) => b.prioritized - a.prioritized || a.wk_level - b.wk_level || b.jlpt_level - a.jlpt_level)
        // arrFiltered.sort((a, b) => b.prioritized - a.prioritized || b.jlpt_level - a.jlpt_level)
      // Other modes: sort by prioritized DESC and then by JLPT level DESC
      } else {
        arrFiltered.sort((a, b) => b.prioritized - a.prioritized || b.jlpt_level - a.jlpt_level)
      }

      // Starting session
      this.$store.dispatch('lessons/initSession', arrFiltered)
      this.loaded = true
    },
    archive () {
      this.$store.dispatch('lessons/archive')

      if (this.lessonsCount === 0) {
        // no more lessons available
        this.$router.push('dashboard')
      } else {
        // continue session
        if (this.lessonIndex > this.lessonsCount - 1) {
          this.prevButton()
        } else {
          this.$store.dispatch('lessons/load')
        }
      }
    },
    toggleFuriganaVisibility () {
      this.$store.dispatch('settings/update', { lessonsHideKana: !this.$store.state.settings.lessonsHideKana })
    },
    nextButton () {
      if (this.lessonIndex < this.lessonsCount - 1) {
        this.$store.dispatch('lessons/next')
      } else {
        // Initiating review/quiz session
        this.$router.push({ name: 'reviews', params: { mode: vars.reviewSessionModes.lessons } })
      }
    },
    prevButton () {
      if (this.lessonIndex > 0) {
        this.$store.dispatch('lessons/previous')
      }
    },
    sendMessage () {},
    keyHandler (event) {
      // Return if key event handling is temporarily disabled (e.g. if v-dialog/v-overlay is active)
      if (!this.$store.state.keysEnabled) return

      // Return if textfield or textarea are focused
      const exclude = ['input', 'textarea']
      if (exclude.indexOf(event.target.tagName.toLowerCase()) !== -1) {
        return
      }

      if (event.code === 'Enter') {
        this.nextButton()
      } else if (event.code === 'Escape') {
        this.prevButton()
      }
    }
  },
  mounted () {
    // Loading session data after a short timeout;
    // Loading the data right away would prevent the loading bar from initiating
    setTimeout(this.initSession, 250)
  },
  created () {
    window.addEventListener('keyup', this.keyHandler)
  },
  beforeDestroy () {
    audio.stop()
    window.removeEventListener('keyup', this.keyHandler)
  }
}
</script>

<style scoped>
.shadow {
  -webkit-text-stroke: 0.5px black;
  text-shadow: 1px 1px 0 black;
}
.pitch--plain {
  border-color: white !important;
}

/* TODO: Without this, v-app-bar will be placed at the top even though the "bottom" prop is used.
This wasn't necessary in previous Vuetify 2 verions.
Could be a bug and might be resolved in Vuetify 3. */
.v-toolbar.v-toolbar--bottom {
  top: auto !important;
  bottom: 0 !important;
}
</style>
