import store from '@/store/store'
import axios from 'axios'

const jishoApiUrl = 'https://jisho.org/api/v1/search/words?keyword='
const corsAnywhereUrl = 'https://torii-cors-anywhere.onrender.com/'

function createEntry (element) {
  const word = element.japanese[0].word || element.slug
  const jlpt = element.jlpt.length > 0 ? parseInt(element.jlpt[0].slice(-1)) : 0

  const otherForms = []
  for (let e = 1; e < element.japanese.length; e++) {
    if (element.japanese[e].word) {
      otherForms.push(`${element.japanese[e].word} 【${element.japanese[e].reading}】`)
    } else {
      otherForms.push(element.japanese[e].reading)
    }
  }

  let wk = null
  for (const e of element.tags) {
    if (e.startsWith('wanikani')) {
      // wk = true
      wk = e.substring(8)
    }
  }

  const senses = []
  element.senses.forEach(sense => {
    senses.push({
      definitions: sense.english_definitions.join(', '),
      pos: sense.parts_of_speech.join(', '),
      info: sense.info,
      usuallyKanaOnly: sense.tags.includes('Usually written using kana alone')
    })
  })

  const inDatabase = store.state.vocabulary.vocab.findIndex(v => v.vocab === word) !== -1

  const obj = {
    word,
    reading: element.japanese[0].reading,
    otherForms,
    common: element.is_common,
    jlpt,
    wk,
    senses,
    inDatabase
  }

  return obj
}

export const jishoparser = {
  searchByKeyword: (keyword) => {
    console.log(`Searching dictionary for '${keyword}' ...`)
    return axios.get(corsAnywhereUrl + jishoApiUrl + keyword,
      {
        withCredentials: false
      }
    ).then(response => {
      const jishoApiResponse = response.data.data
      console.log(`Found ${jishoApiResponse.length} entries.`)

      if (jishoApiResponse.length > 0) {
        const searchResults = []
        for (const e of jishoApiResponse) {
          searchResults.push(createEntry(e))
        }
        return searchResults
      } else {
        return []
      }
    }).catch(error => {
      console.log(error)
    })
  }
}
